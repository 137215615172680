/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"


function SEO({ description, lang, meta, title, seo, image }) {
  
  const { site, wintechFile, kingFile } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
        wintechFile: file(relativePath: { eq: "default-banner.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
        }
        kingFile: file(relativePath: { eq: "default-banner-king.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
        }
      }
    `
  )
  
  const metaDescription = description || site.siteMetadata.description;
  const metaTitle = title || site.siteMetadata.title; //default
  
  let themeClass = 'themeWintech';
  if(process.env.GATSBY_THEME == "King") {
    themeClass = 'themeKing';
  }

  let defaultPostImageSrc = wintechFile;
  let rootDomain = "https://wintechracing.com";
  if(process.env.GATSBY_THEME == "King") {
    defaultPostImageSrc = kingFile;
    rootDomain = "https://www.kingracingshells.com";
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      bodyAttributes={{
        class: metaTitle.toLowerCase().replace(/[^a-z0-9]+/g,'-')+" "+themeClass
      }}
      title={seo ? seo.title : metaTitle}
      meta={[
        {
          name: `description`,
          content: (seo && seo.metaDesc !== '' ? seo.metaDesc : metaDescription),
        },
        {
          name: `keywords`,
          content: (seo && seo.metaKeywords !== '' ? seo.metaKeywords : ''),
        },
        {
          property: `og:title`,
          content: (seo && seo.opengraphTitle !== '' ? seo.opengraphTitle : (seo && seo.title ? seo.title : metaTitle)),
        },
        {
          property: `og:description`,
          content: (seo && seo.opengraphDescription !== '' ? seo.opengraphDescription : (seo && seo.metaDesc ? seo.metaDesc : metaDescription)),
        },
        {
          name: `og:image`,
          content: rootDomain+(image ? image.node.imageFile.childImageSharp.fluid.src : defaultPostImageSrc.childImageSharp.fluid.src),
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: (seo && seo.twitterTitle !== '' ? seo.twitterTitle : (seo && seo.title ? seo.title : metaTitle)),
        },
        {
          name: `twitter:description`,
          content: (seo && seo.twitterDescription !== '' ? seo.twitterDescription : (seo && seo.metaDesc ? seo.metaDesc : metaDescription)),
        },{
          name: `twitter:image`,
          content: rootDomain+(image ? image.node.imageFile.childImageSharp.fluid.src : defaultPostImageSrc.childImageSharp.fluid.src),
        },
      ].concat(meta)}
    >

   <script async src="https://www.googletagmanager.com/gtag/js?id=G-YCQN5Y5Q0P" type="text/javascript"/>
    <script type="text/javascript">
    {`
     window.dataLayer = window.dataLayer || [];
     function gtag(){dataLayer.push(arguments);}
     gtag('js', new Date());
     gtag('config', 'G-YCQN5Y5Q0P');
     `}
    </script>



    <script async src="https://www.googletagmanager.com/gtag/js?id=UA-63389378-2" type="text/javascript"/>
    <script type="text/javascript">
    {`
      if (typeof window !== 'undefined' && window) {
     window.dataLayer = window.dataLayer || [];
     function gtag(){dataLayer.push(arguments);}
     gtag('js', new Date());
     gtag('config', 'UA-63389378-2');
    }
     `}
    </script>

<script type="text/javascript">
{`
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-K82LLBGZ');
`}
</script>


<script async src="https://www.googletagmanager.com/gtag/js?id=AW-10855496003" type="text/javascript"/>
<script type="text/javascript">
{`
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); 
  gtag('config', 'AW-10855496003');
`}
</script>

      <script type="text/javascript">
      {`

        if (typeof window !== 'undefined' && window) {


          // kill the SW
          let foundSW = false;
          navigator.serviceWorker.getRegistrations().then(registrations => {
              for (const registration of registrations) {
                  registration.unregister();
                  foundSW = true;
              } 
          });
          if(foundSW) {
            // reload so page is free of it:
            window.location.reload();
          }



          fetch('https://ipapi.co/json/')
          .then(function(response) {
            response.json().then(jsonData => {
              const countryMappings = {
                'US' : { 'Name':'United States', 'Store':'US' },
                'AG' : { 'Name':'Antigua and Barbuda', 'Store':'US' },
                'BS' : { 'Name':'The Bahamas', 'Store':'US' },
                'BB' : { 'Name':'Barbados', 'Store':'US' },
                'BZ' : { 'Name':'Belize', 'Store':'US' },
                'CA' : { 'Name':'Canada', 'Store':'US' },
                'CR' : { 'Name':'Costa Rica', 'Store':'US' },
                'CU' : { 'Name':'Cuba', 'Store':'US' },
                'DM' : { 'Name':'Dominica', 'Store':'US' },
                'DO' : { 'Name':'Dominican Republic', 'Store':'US' },
                'SV' : { 'Name':'El Salvador', 'Store':'US' },
                'GD' : { 'Name':'Grenada', 'Store':'US' },
                'GT' : { 'Name':'Guatemala', 'Store':'US' },
                'HT' : { 'Name':'Haiti', 'Store':'US' },
                'HN' : { 'Name':'Honduras', 'Store':'US' },
                'JM' : { 'Name':'Jamaica', 'Store':'US' },
                'MX' : { 'Name':'Mexico', 'Store':'US' },
                'NI' : { 'Name':'Nicaragua', 'Store':'US' },
                'PA' : { 'Name':'Panama', 'Store':'US' },
                'KN' : { 'Name':'Saint Kitts and Nevis', 'Store':'US' },
                'LC' : { 'Name':'Saint Lucia', 'Store':'US' },
                'VC' : { 'Name':'Saint Vincent and the Grenadines', 'Store':'US' },
                'TT' : { 'Name':'Trinidad and Tobago', 'Store':'US' },
                'GB' : { 'Name':'United Kingdom', 'Store':'GB' },
                'FR' : { 'Name':'United Kingdom', 'Store':'GB' },
                'DE' : { 'Name':'United Kingdom', 'Store':'GB' },
                'ES' : { 'Name':'United Kingdom', 'Store':'GB' },
                'PL' : { 'Name':'United Kingdom', 'Store':'GB' },
                'ZH' : { 'Name':'United Kingdom', 'Store':'GB' },
              };
              let requiredStore = 'ROW';
              var hasRedirected = window.location.search.indexOf('geo-redirection');
              if(hasRedirected === -1) {

                if (countryMappings.hasOwnProperty(jsonData.country_code)) {
                  requiredStore = countryMappings[jsonData.country_code].Store;
                } 
                if(requiredStore == 'US') {
                  window.location.assign('https://store.wintechracing.com'+window.location.pathname+'?redirected=US');
                } else if ((jsonData.country_code == 'FR') || (jsonData.country_code == 'DE') || (jsonData.country_code == 'ES') || (jsonData.country_code == 'PL') || (jsonData.country_code == 'ZH')) {
                  // do nothing
                } else {
                  window.location.assign('https://www.wintechking.com'+window.location.pathname+'?redirected='+jsonData.country_code);
                }
              }

            });
          })
          .catch(function(error) {
            console.log(error)
          });
        }
      `}
      </script>


    
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO;